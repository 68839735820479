@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root{
  --padding:1vw;
  --red_color:#eb4a62;
  --red_color_hover:#c43d51;
  --red_color_light:#eb4a62b4;
  --blue_color:#6a07d4;
  --blue_color_light:#b883f1af;
  --blue_color_hover:#5204a5;
  --border-radius: 1vw;
}

a{text-decoration: none;}
body{
  background-color: #e7edfe;
  padding:0;
  padding-right: 0;
  width: 100%; 
  height: 100vh;
  margin: 0px;
  overflow: hidden;
}
*{padding: 0px; margin: 0px; box-sizing: border-box; transition: 0s; font-family: 'Poppins', sans-serif !important;}

*::-webkit-scrollbar {
  width: 0.5vw;
}
.ant-table-wrapper::-webkit-scrollbar {
  height: 0.5vw;
}

/* Track */
*::-webkit-scrollbar-track {
  border-radius: 1vw;
  background-color:#494b7440;
}
 
/* Handle */
*::-webkit-scrollbar-thumb {
  background: #1e1e2d; 
  border-radius: 10px;
}
.menuList::-webkit-scrollbar-track {
  border-radius: 1vw;
  background-color:#1e1e2d;
}
 
/* Handle */
.menuList::-webkit-scrollbar-thumb {
  background: #1e1e2d; 
  border-radius: 10px;
}
/* Handle on hover */
.menuList:hover::-webkit-scrollbar-thumb{
  background: #494b74; 
}
h1, h2, h3, h4, h5, h6, p{margin-bottom: 0px;}
svg, svg *, .ant-select-dropdown, .ant-select-dropdown *,.ant-picker-dropdown, .ant-picker-dropdown *{transition: 0s !important;}
.bigBox{
  width: 100%; 
  height: 100%; 
  overflow: hidden;
  display: flex;
}
.bigMenu{width: 17%; height: 100vh;  position: fixed; top: 0px; left: 0px; }
.bigContent{width: 83%; left: 17%; position: fixed; top: 0px; padding:0; height:100vh; overflow-y: auto; overflow-x: hidden;}
.menuDiv{height:100%; width: 100%;background-color: #1e1e2d;}
.menuDiv>div{ width: 100%;}
.brand{ height: 12vh; background-color: var(--red_color);
display: flex; align-items: center; justify-content: space-between; padding-right: var(--padding);
}
.menu{display: flex; flex-direction: column; overflow: hidden;
   height: calc(88vh - 2.1vw); margin-top: var(--padding); background-color: white;}
.support{height: 20vh; margin-top: var(--padding);}
.menuHeader{height: 4vw; display: flex; align-items: center; justify-content: center; background-color: #161623; color: white;
font-size:1.2vw; font-weight: 600; margin-bottom:var(--padding);}
.menuItem{display: flex; padding: var(--padding); width: 100%; align-items: center; color: #494b74; font-size: 1.5vw;}
.menuItem p{color: #a2a3b7; font-size:0.9vw; margin-left: var(--padding);}
.drop_title{color: #4c4e6f; cursor: pointer; font-size: 0.8vw; display: flex; justify-content: space-between; align-items: center;
   padding:var(--padding); font-weight: 400; text-transform: uppercase;}
   .drop_icon{font-size: 1.3vw;}
  .drop_title:hover{background-color: #161623;}
  .opened_title{background-color: #161623;}
  .drop{max-height: 0px; transition: 0.3s !important; overflow: hidden;}
  .opened_drop_user{max-height: 35vw;}
   .menuItem:hover{background-color: #161623;}
.active_menu{background-color: #161623;}
.menuList{height: calc(100vh - 5vw); overflow-y: auto !important; padding-bottom: 2vw;}
.content{width: 100%; min-height: calc(100vh - 4vw); padding: var(--padding);}
.contentHeader{background-color: white; height: 4vw; width: 100%;}
.contentBody{width: 100%; height: 100%; background-color: white;}
.login_big_box{width: 100%; position: absolute; top: 0px; left: 0px; height: 100vh; display: flex; align-items: center; justify-content: center; 
  background-color: #080710;}
   .form_box{width: 35%;  position: relative; height: auto; }
.login_box{width: 100%; padding: 1vw; border-radius: var(--border-radius);  background-color: rgba(255,255,255,0.13); 
  backdrop-filter: blur(10px); border: 0.15vw solid rgba(255,255,255,0.1);z-index: 43 !important; 
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);   }
  .login_box h1{text-transform: uppercase; font-size: 2vw; color: white; width: 100%; text-align: center; margin-bottom: 1vw;}
  .login_btn{width: 100%; background-color: white; color: black; text-transform: uppercase;
margin-top: 1vw;font-size: 0.9vw; height: auto; font-weight: 500;  padding: 0.5vw;
  }
  .login_btn:hover{background-color: rgb(7, 76, 238) !important; color: white !important;}
  .red_box_form{content: ""; position: absolute; top: -4vw; left: -4vw; width: 10vw; height: 10vw; border-radius: 50%; 
  z-index: -0;  background: linear-gradient( var(--red_color), var(--red_color_hover));}
.blue_box_form{content: ""; position: absolute; bottom: -4vw; right: -4vw; width: 10vw; height: 10vw; border-radius: 50%; 
  background: linear-gradient( var(--blue_color), var(--blue_color_hover)); z-index: 0;}
  .ant-form-item-required::before{display: none !important;}

  .btns_form{height: auto; font-size: 0.9vw; width: 45%; padding: 0.5vw 1vw; background-color: rgba(255,255,255,0.27);
  color: #eaf0fb; border-radius: 3px; text-align: center;}
  .btns_form:hover{background-color: white; color: black;}
  .ant-message{ z-index: 8888 !important;}
  .ant-message-notice-error .ant-message-notice-content{background: #f52a2a !important; padding: 1vw !important;}
  .ant-message-notice-success .ant-message-notice-content{background: #2ad200 !important; padding: 1vw !important;}
  .ant-message-custom-content span{color: white !important; font-weight: 500; font-size: 0.8vw;}
  .ant-message-custom-content .anticon{font-size: 1.3vw !important; width: 1.5vw !important;}
  .ant-message-custom-content{display: flex !important; align-items: center;}
  .email_code_div{position: relative;}
  .email_time{width: 4vw; position: absolute; right: 1.5vw; top: 2vw; height: 2.5vw; display: flex; align-items: center;
  justify-content: space-between;
  }
  .email_time span{font-size: 1.3vw; font-weight: 500;  display: flex; width: 40% !important; justify-content: center;}
  .email_time span:nth-child(2){width: 20% !important;}
.red_time span{color: rgba(232, 28, 28, 0.767);}
.headerBox{width: 100%; height: 100%; display: flex; padding: var(--padding); justify-content: space-between; align-items: center;}
.input{width: 100%; height: 2.5vw; color: #1f1f1f;  padding-top: 0.2vw !important;
  padding-bottom: 0.2vw !important; border: 0.12vw solid #66717460; box-shadow: none !important;}
.ant-select-selector, .date_input{width: 100%; height: 3vw !important; color: #1f1f1f !important;padding-top: 0.2vw !important;
  padding-bottom: 0.2vw !important;
  font-size: 14px !important; border: 0.12vw solid #66717460 !important; box-shadow: none !important;}
.headerBox .input{width: 22vw;}
.input input, .select input, .date_input input, textarea{height: 100% !important; font-size: 14px !important;border-radius: 0 !important; appearance: none !important; line-height: 0.9vw !important; padding: 0px !important;}
.input_icon{color: #66717460;}
.ant-select-arrow{top: 60% !important;}
table{font-size: 14px !important;}
table th, table td{padding: 10px !important;}
.ant-btn{font-size: 14px !important; display: flex !important; align-items: center; justify-content: center; height: auto !important; 
 padding: 0.3vw 1vw !important;}
.ant-table-column-sorter-inner *{font-size: 14px !important;}
label{font-size: 14px !important; color: #1f1f1f !important; font-weight: 500 !important;  height: auto !important;}
.ant-form-item-label{padding-bottom: 0.1vw !important;}
.login_big_box label{color: white !important;}
.ant-modal-title, .ant-modal-confirm-title{font-size: 1.2vw !important; padding: var(--padding) !important; border-bottom: 1px solid #66717460; }
.ant-modal-content{padding: 0 !important;}
.modal_bottom .ant-modal-content{padding-bottom: var(--padding) !important;}

.ant-modal-confirm-title{padding-top: 0px !important;}
.ant-modal-close{top: var(--padding) !important; right: var(--padding) !important;}
.ant-modal-close *{font-size: 1.2vw !important; }
.ant-modal-body, .ant-modal-confirm-body{padding: var(--padding); position: relative; max-height: 80vh; overflow-y: auto;}
.btn_box{width: 100%; display: flex; justify-content: flex-end; margin-top: 1.5vw;}
.btn_box button{margin-left: var(--padding) !important}
.btn_form_div{margin-bottom: 0px !important;}
th{white-space: nowrap;}
.mini_column{max-width: 5% !important;}
.ant-btn-circle{width: 2vw !important;min-width: 2vw !important; height: 2vw !important;}
.warning_btn{background-color: orange;}
.warning_btn:hover{background-color: orange !important; opacity: 0.7 !important;}
.ant-modal-confirm-body svg{color: orange !important; font-size: 2vw;}
.ant-modal-confirm-btns{display: flex; justify-content: flex-end;}
.ant-modal-confirm-btns button{margin-left: var(--padding);}
.ant-modal-confirm-content{font-size: 14px !important;}
.ant-modal-confirm-btns button:nth-child(1){background-color: rgb(246, 45, 45); color: white !important; border: none !important;}
.ant-modal-confirm-btns button:nth-child(2){background-color: rgb(51, 195, 32); color: white !important; border: none !important;}
.head_btn_box{display: flex;}
.head_btn_box button{margin-right: 1vw;}

.ant-modal-confirm-btns button:nth-child(1):hover{background-color: rgb(246, 45, 45) !important; opacity: 0.8; color: white !important; border: none !important;}
.ant-modal-confirm-btns button:nth-child(2):hover{background-color: rgb(51, 195, 32) !important; opacity: 0.8; color: white !important; border: none !important;}
.ant-pagination-item{padding: 0 !important; width: 2.2vw !important; height: 2.2vw !important; display: flex !important; align-items: center; justify-content: center;
   font-size: 14px !important; min-width: auto !important;}
.ant-pagination-item a{padding:0px !important; height: auto !important;}
.table_btn_box{width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;}
.modal_text{font-size: 0.9vw;}
.form_col{padding: var(--padding);}
.modal_bottom .btn_box{position: relative; top: var(--padding);}
.big_quill{width: 100% !important; height: 60vh !important; margin-bottom: 3vw;}
.big_quill button{padding: 4px !important;}
.modal_bottom{top: 2.5vh !important;}
.big_quill img, .modal_text img{max-width: 80% !important; margin: auto; display: flex;}
.pagination_div{display: flex; justify-content: space-between; align-items: center;}
.pagination_div>p{white-space: nowrap; padding-left: var(--padding); font-size: 0.8vw; font-weight: 500; }
.pagination{width: 100%; display: flex; padding-top: 2vw; padding-bottom: 2vw; justify-content: flex-end; align-items: center;}
.pagination_box{width: 2.5vw; margin-left: 0.5vw; height: 2.5vw; border-radius: 50%; display: flex; align-items: center;  cursor: pointer; justify-content: center;}
.pagination_arrow{font-size: 2vw; color: #494b74; opacity: 1;}
.pagination_number{background-color: transparent; font-weight: 500; font-size: 1.2vw; color: #494b74; border: 0.2vw solid #494b74;}
.pagination_number:hover{background-color: #494b74; color: white;}
.pagination_arrow:hover{opacity: 0.8;}
.disabled_pag{opacity: 0 !important; cursor: default;}
.active_pag{background-color: #494b74; color: white;}
.btn_head{background-color: #4c4e6f; color: white !important;}
.btn_head:hover{background-color: #161623 !important;}
.table_img{width: 3vw; height: 3vw; border-radius: 50%; border: 0.15vw solid #494b74; overflow: hidden; background-repeat: no-repeat; background-size: cover;
background-position: center center;
}
.filter_btn{background-color: orange;}
.filter_btn:hover{background-color: rgb(235, 153, 1) !important;}
.anticon-close-circle, .anticon-check-circle{transform: scale(1.2, 1.2);}
.self_top{display: flex; padding: 2vw;}
.self_top .avatar{width: 10vw !important; height: 10vw !important;}
.self_row{width: calc(100% - 10vw); margin-left: 2vw; background-color: white; padding: 0vw 1.5vw; border-radius: 1vw;} 
.title_self_profile{font-size: 1.4vw; padding: 0.5vw 1vw;margin: 1.5vw 0vw; border-bottom:2px solid #494b74; color: #4c4e6f;}
.avatars_box{width: 100%; display: flex; }
.avatars_item{padding: var(--padding) 0.8vw; width: 20%;  border-radius: var(--border-radius); background-color: white;} 
.avatars_img{width: 100%; height: 13vw; border-radius: var(--border-radius); background-size: cover; transition: 0s !important;
   background-position: center center; background-repeat: no-repeat;}
textarea{ padding: 0.2vw !important; line-height: 1.2vw !important; padding-left: 0.3vw !important; padding-right: 1.5vw !important;} 
.avatars_title{padding-left: 1vw; margin-bottom: 0vw; color: #4c4e6f; font-size: 0.9vw; font-weight: 500;}
.avatars_btn{margin-top: var(--padding); width: 100%; display: flex; justify-content: space-between; align-items: center;}
.avatars_btn .avatar_delete{font-size: 1.2vw !important; width: 2vw !important; height: 2vw !important; background-color: #4c4e6f;padding: 0px !important;}
.avatars_btn .avatar_delete svg{top: 0vw !important;}
.avatars_btn .avatar_delete:hover{background-color: #161623 !important;}
.avatars_btn .avatar_delete svg{position: relative; top: -0.5vw; left: 0vw;} 
.add_avatar{position: relative; display: flex; overflow: hidden; align-items: center; justify-content: center;}
.add_avatar input{transform: scale(4, 10); opacity: 0; position: absolute; z-index: 333; cursor: pointer;}
.add_avatar .avatars_img{width: 100%; display: flex; align-items:center; justify-content: center; color: #4c4e6f;
border: 0.3vw solid #4c4e6f; font-size: 15vw; transition: 0s !important; 
}
.add_avatar:hover .avatars_img{border-color: #4c4e6f; color: #4c4e6f;}
.avatars_img span{position: relative; top: 0vw;}

.ant-form-item-label{padding-bottom: 0.3vw !important;}
.avatar{height: 3.3vw; width: 3.3vw; border-radius: 50%;  display: flex; align-items: center; justify-content: center;
  border: 2px solid white; overflow: hidden; position: relative; margin-top: 3vw;
  }
  .avatar:after{width: 100%; content: ""; height: 100%; position: absolute; top: 0px; left: 0px;}
  .avatar .avatar_img{height: 100%; width: 100%; background-size: cover; background-position:center; background-repeat: no-repeat;}
:where(.css-dev-only-do-not-override-2i2tap).ant-select-single{ height: auto !important;}
.ant-input-number-input-wrap, .ant-input-number{display: flex !important; align-items: center !important;}
.avatars_box .ant-switch-disabled{opacity: 1 !important;}
.add_avatar{cursor: pointer !important;}
.red_btn{background-color: rgb(235, 29, 29) !important;}
.red_btn:hover{background-color: darkred !important;}
.btnBox{display: flex; justify-content: space-between; width: 100%;}
.ant-table-wrapper{overflow-x: auto !important; width: 100% !important;}
.select_box{width: 25%; padding: 1vw;}
.select{border-radius: 6px; outline: none;}
.ant-select-selector{padding-top: 0 !important; padding-bottom: 0 !important; height: 2.5vw !important;}
:where(.css-dev-only-do-not-override-2i2tap).ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{border-color: #ff4d4f !important;}
:where(.css-dev-only-do-not-override-2i2tap).ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector:hover{border-color: #ffa39e !important;}
.ant-select-selection-item{font-size: 14px !important;}
.select_box_div{display: flex; width: 100%;}
.error_text{color:rgb(235, 29, 29)}
.success_text{color:rgb(5, 201, 28)}
.images_ads{display: flex; flex-wrap: wrap;}
.images_ads_box{width: 33.33%; padding: 20px; display: flex; justify-content: center;}
.images_ads_box img{width: 100%;}
.yandex_box{height: 400px; padding: 20px; width: 100%;}
.add_contact_box{display: flex; align-items: center; justify-content: space-between; width: 100%; padding: 20px; margin-top: 40px;}
.user_box{display: flex; align-items: center;}
.add_contact_box .avatar{margin: 0px; margin-right: 10px; background-size: cover; background-repeat: no-repeat; background-position: center;}
.user_text h1{font-size: 20px; }
.user_text a{font-size: 16px; margin-top: 10px; color: #494b74;} 
.add_contact_links{display: flex; align-items: center;}
.add_contact_links a{font-size: 25px; margin: 0 10px; color: black;}
.product_text{padding: 20px;}
.head_text_title h1{font-size: 24px;  font-weight: 700; margin-bottom: 5px;}
.head_text_title p{font-size: 16px; margin-bottom: 20px;}
.product_item{font-size: 16px; margin-bottom: 10px;}
.product_price{font-size: 27px; margin: 20px 0; font-weight: 700;  color: #6a07d4;}
.product_comment *{font-size: 16px !important; font-weight: 500;}
.images{width: 100%; flex-wrap: wrap; display: flex; justify-content: space-between; }
.img_box{width: 47%; margin: 20px 0; height: 250px; background-position: center; background-size: cover;}
