:root {
	--border-width: 7px;
  }
  
  * {
	margin: 0;
	padding: 0;
  }
  
 .loader_box {
	width: 100%; height: 100vh; position: fixed; top: 0px; left: 0px; z-index: 5888;
	background-color: #1b1b2895; backdrop-filter: blur(7px);
  }
  
  .sec-loading {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .sec-loading .one {
	height: 80px;
	width: 80px;
	border: var(--border-width) solid white;
	transform: rotate(45deg);
	border-radius: 0 50% 50% 50%;
	position: relative;
	animation: move 0.5s linear infinite alternate-reverse;
  }
  .sec-loading .one::before {
	content: "";
	position: absolute;
	height: 55%;
	width: 55%;
	border-radius: 50%;
	border: var(--border-width) solid transparent;
	border-top-color: white;
	border-bottom-color: white;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: rotate 1s linear infinite;
  }
  
  @keyframes rotate {
	to {
	  transform: translate(-50%, -50%) rotate(360deg);
	}
  }
  @keyframes move {
	to {
	  transform: translateY(15px) rotate(45deg);
	}
  }
  